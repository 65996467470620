<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="376" height="376" fill="none">
    <path
      fill="#FAFAFA"
      d="M250.07 189.86h-61.83v61.99c7.62-.02 14.91-1.43 21.66-3.97 14.06-5.31 25.71-15.58 32.78-28.68a61.67 61.67 0 0 0 7.4-29.34" />
    <path
      fill="#F4FAFF"
      d="M188.24 251.86v-61.99h-62.16c0 10.57 2.66 20.52 7.34 29.24 7.03 13.09 18.62 23.36 32.63 28.71a61.719 61.719 0 0 0 22.02 4.06h.16" />
    <path
      fill="#000"
      d="M250.07 189.86c0-4.45-.48-8.78-1.37-12.96-3.04-14.24-10.99-26.68-21.99-35.47-10.57-8.45-23.93-13.52-38.47-13.56v62h61.83v-.01Z" />
    <path
      fill="#000032"
      d="M188.24 189.86v-62h-.27c-14.62.03-28.06 5.15-38.66 13.67-10.93 8.79-18.83 21.2-21.86 35.4a61.83 61.83 0 0 0-1.36 12.92h62.16l-.01.01Z" />
    <path
      fill="#0064FF"
      d="M188.069 115.42c17.65 0 33.87 6.17 46.65 16.47l33-39.33c-21.71-17.8-49.45-28.5-79.65-28.5h-.11v51.36h.11Z" />
    <path
      fill="#222"
      d="M188.069 115.42c17.65 0 33.87 6.17 46.65 16.47l33-39.33c-21.71-17.8-49.45-28.5-79.65-28.5h-.11v51.36h.11Z" />
    <path
      fill="#0064FF"
      d="M141.289 132c12.77-10.34 29-16.55 46.67-16.58V64.06c-30.22.03-57.97 10.76-79.67 28.61l33 39.33Z" />
    <path
      fill="#0037A2"
      d="M141.289 132c12.77-10.34 29-16.55 46.67-16.58V64.06c-30.22.03-57.97 10.76-79.67 28.61l33 39.33Z" />
    <path
      fill="#0064FF"
      d="m141.29 132-33-39.33c-22.19 18.25-38.04 43.93-43.71 73.19l50.59 8.92c3.54-17.14 13-32.15 26.13-42.78" />
    <path
      fill="#62A3FF"
      d="m141.29 132-33-39.33c-22.19 18.25-38.04 43.93-43.71 73.19l50.59 8.92c3.54-17.14 13-32.15 26.13-42.78" />
    <path
      fill="#0064FF"
      d="M113.63 189.86c0-5.17.53-10.21 1.54-15.09l-50.59-8.92a125.79 125.79 0 0 0-2.31 24.01c0 22.18 5.78 43.03 15.89 61.14l44.47-25.68c-5.74-10.55-9-22.64-9-35.47" />
    <path
      fill="#70D6FF"
      d="M113.63 189.86c0-5.17.53-10.21 1.54-15.09l-50.59-8.92a125.79 125.79 0 0 0-2.31 24.01c0 22.18 5.78 43.03 15.89 61.14l44.47-25.68c-5.74-10.55-9-22.64-9-35.47" />
    <path
      fill="#0064FF"
      d="m122.63 225.33-44.47 25.68c14.49 25.95 37.9 46.27 66.06 56.77l17.57-48.26c-16.77-6.35-30.67-18.59-39.16-34.18" />
    <path
      fill="#A0E6FA"
      d="m122.63 225.33-44.47 25.68c14.49 25.95 37.9 46.27 66.06 56.77l17.57-48.26c-16.77-6.35-30.67-18.59-39.16-34.18" />
    <path
      fill="#0064FF"
      d="M188.069 264.31c-9.25 0-18.11-1.7-26.28-4.8l-17.57 48.26c13.66 5.1 28.43 7.89 43.85 7.89 15.42 0 30.05-2.76 43.65-7.81l-17.57-48.26a74.025 74.025 0 0 1-26.08 4.72Z" />
    <path
      fill="#D7F5FF"
      d="M188.069 264.31c-9.25 0-18.11-1.7-26.28-4.8l-17.57 48.26c13.66 5.1 28.43 7.89 43.85 7.89 15.42 0 30.05-2.76 43.65-7.81l-17.57-48.26a74.025 74.025 0 0 1-26.08 4.72Z" />
    <path
      fill="#0064FF"
      d="m214.16 259.59 17.57 48.26c28.21-10.47 51.67-30.79 66.21-56.75l-44.47-25.67c-8.52 15.61-22.48 27.85-39.3 34.16" />
    <path
      fill="#DDD"
      d="m214.16 259.59 17.57 48.26c28.21-10.47 51.67-30.79 66.21-56.75l-44.47-25.67c-8.52 15.61-22.48 27.85-39.3 34.16" />
    <path
      fill="#0064FF"
      d="M262.519 189.86c0 12.87-3.28 24.99-9.06 35.57l44.47 25.67c10.15-18.14 15.95-39.02 15.95-61.24 0-8.22-.8-16.26-2.32-24.04l-50.59 8.92a74.64 74.64 0 0 1 1.55 15.12Z" />
    <path
      fill="#696969"
      d="M262.519 189.86c0 12.87-3.28 24.99-9.06 35.57l44.47 25.67c10.15-18.14 15.95-39.02 15.95-61.24 0-8.22-.8-16.26-2.32-24.04l-50.59 8.92a74.64 74.64 0 0 1 1.55 15.12Z" />
    <path
      fill="#0064FF"
      d="m260.969 174.74 50.59-8.92c-5.7-29.31-21.59-55.02-43.84-73.26l-33 39.33c13.19 10.63 22.7 25.67 26.26 42.85" />
    <path
      fill="#333"
      d="m260.969 174.74 50.59-8.92c-5.7-29.31-21.59-55.02-43.84-73.26l-33 39.33c13.19 10.63 22.7 25.67 26.26 42.85" />
    <path
      fill="red"
      d="M372.439 155.02c-7.99-45.3-28.92-81.56-64.16-111.13C273.039 14.32 233.699 0 187.699 0v38.08h.03v16.44h.11c32.53 0 62.41 11.54 85.78 30.74 23.92 19.66 40.99 47.35 47.08 78.91 1.6 8.31 2.45 16.88 2.45 25.65 0 23.96-6.27 46.48-17.24 66.02-15.65 27.87-40.86 49.66-71.17 60.9-14.62 5.42-30.41 8.38-46.89 8.38h-.11v16.93h-.03v33.63c21.27 0 42.55-3.93 64.16-11.8 43.23-15.73 75.3-42.65 98.3-82.48 23-39.84 30.27-81.07 22.28-126.37" />
    <path
      fill="#0064FF"
      d="M187.959 375.41v-50.48c-16.49-.01-32.29-3-46.91-8.44-30.21-11.25-55.35-33.02-70.93-60.83-10.92-19.49-17.16-41.95-17.16-65.84 0-8.74.84-17.29 2.44-25.57 6.05-31.47 23.06-59.1 46.89-78.74 23.32-19.22 53.18-30.78 85.68-30.8V.26c-45.94 0-85.23 14.3-120.42 43.83-35.19 29.52-56.1 65.73-64.08 110.97-7.98 45.24-.72 86.41 22.25 126.2 22.97 39.78 55 66.66 98.16 82.37 21.58 7.86 42.83 11.78 64.07 11.78" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
