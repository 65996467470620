<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`"
      d="M8 13.316a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-7a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" />
    <path
      :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`"
      d="M8 3.316a1 1 0 0 1-1-1v-.5a1 1 0 0 1 2 0v.5a1 1 0 0 1-1 1ZM8 16.816a1 1 0 0 1-1-1v-.5a1 1 0 1 1 2 0v.5a1 1 0 0 1-1 1ZM1.5 9.816H1a1 1 0 0 1 0-2h.5a1 1 0 1 1 0 2ZM15 9.816h-.5a1 1 0 1 1 0-2h.5a1 1 0 1 1 0 2ZM3.405 5.222a1 1 0 0 1-.707-.295l-.355-.36A1 1 0 0 1 3.75 3.159l.353.355a1 1 0 0 1-.705 1.708h.007ZM12.95 14.765a1 1 0 0 1-.7-.292l-.353-.355a1.002 1.002 0 0 1 .3-1.67.999.999 0 0 1 1.112.258l.348.36a1 1 0 0 1-.708 1.7ZM12.596 5.222a1 1 0 0 1-.705-1.708l.36-.355a1 1 0 0 1 1.407 1.408l-.355.352a1 1 0 0 1-.707.303ZM3.05 14.766a1 1 0 0 1-.707-1.7l.355-.353a1 1 0 1 1 1.412 1.413l-.36.347a1 1 0 0 1-.7.293Z" />
  </svg>
</template>
<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
  hover1?: string | null
  hover2?: string | null
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-blue',
  hover1: null,
  hover2: null,
})
</script>
