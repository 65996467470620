<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
    <path
      :class="`fill-${fill1}`"
      d="M11.25 12H.75a.75.75 0 0 1-.75-.75v-3a.75.75 0 1 1 1.5 0v2.25h9V8.25a.75.75 0 1 1 1.5 0v3a.75.75 0 0 1-.75.75Z" />
    <path
      :class="`fill-${fill2}`"
      d="M10.283 4.386a.751.751 0 0 0-1.061-.004L6.75 6.838V.75a.75.75 0 1 0-1.5 0v6.14L2.768 4.525a.753.753 0 0 0-1.061.025.753.753 0 0 0 .024 1.061l3.75 3.576c.015.013.03.022.045.035.02.017.04.034.062.049s.045.024.067.036c.021.01.04.022.06.03.027.01.055.018.083.026l.054.015a.755.755 0 0 0 .29 0c.022-.004.04-.013.063-.019.026-.007.052-.013.077-.024.024-.01.045-.025.067-.036.02-.011.041-.02.06-.034a.996.996 0 0 0 .086-.069c.01-.008.02-.013.029-.023l3.75-3.727a.752.752 0 0 0 .003-1.061l.006.002Z" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1?: string
  fill2?: string
}
withDefaults(defineProps<Props>(), {
  fill1: 'otivo-red',
  fill2: 'otivo-blue',
})
</script>
