<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9799 0.481121H11.1398C10.9398 0.481121 10.7598 0.541125 10.5697 0.671135H10.3997C10.0197 0.311109 9.54967 0.121095 9.03963 0.121095L3.1792 0.121095C1.8191 0.121095 0.949037 0.88115 0.789025 2.16125L-0.00103283 7.25162V7.42163C-0.00103283 8.58172 0.909034 9.53179 2.03912 9.53179L4.72932 9.53179L4.86933 9.6718V11.6019C4.86933 12.592 5.14935 13.3021 5.69939 13.7121C6.34944 14.2021 7.12949 14.1921 7.80954 13.9921C7.99956 13.9221 8.12957 13.7421 8.12957 13.5221V11.0419C8.12957 10.2118 8.5496 9.56179 9.23965 9.07175C9.60968 8.81174 9.84969 8.70173 10.2997 8.53171L10.4397 8.56172C10.6198 8.75173 10.8798 8.86174 11.1498 8.86174H12.9899C13.54 8.87174 14 8.40171 14 7.83166L14 1.5412C13.98 0.951156 13.52 0.481121 12.9799 0.481121ZM10.1297 7.44163L10.0297 7.58165C9.55967 7.73166 8.99963 8.00168 8.47959 8.42171C7.65953 9.08176 7.1895 9.93182 7.1895 11.0219V12.972L7.05949 13.1121C6.74946 13.1421 6.43944 13.0721 6.21943 12.912C5.9094 12.692 5.76939 12.252 5.76939 11.6019V9.03175C5.76939 8.77173 5.55938 8.55172 5.30936 8.55172L2.01912 8.55172C1.40907 8.55172 0.909034 8.04168 0.909034 7.40163V7.36163L0.999041 6.77159L1.13905 6.65158L5.15935 6.65158C5.40937 6.57157 5.56938 6.36156 5.56938 6.16154C5.56938 5.95152 5.40937 5.75151 5.19935 5.6715H1.16905L1.38907 4.2314L1.52908 4.11139H5.16935C5.40937 4.07139 5.56938 3.88137 5.56938 3.62135C5.56938 3.36133 5.41937 3.19132 5.18935 3.16132H1.55908L1.58908 3.00131C1.60909 2.8613 1.64909 2.62128 1.70909 2.31126C1.70909 2.31126 1.70909 2.29125 1.70909 2.27125C1.7391 2.03123 1.7691 1.91123 1.8491 1.75121C2.04912 1.33118 2.46915 1.08117 3.1692 1.08117L9.02963 1.08117C9.63968 1.08117 10.1397 1.5912 10.1397 2.23125L10.1397 7.45164L10.1297 7.44163ZM13.0499 7.74166L12.9099 7.88167H11.2098L11.0698 7.74166L11.0698 1.5812L11.2098 1.44119H12.9099L13.0499 1.5812L13.0499 7.74166Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-1'
})
</script>
