<template>
  <svg xmlns="http://www.w3.org/2000/svg" :fill="fill" width="12" height="6">
    <path
      d="M.75 0a.7.7 0 0 1 .436.154L6.001 3.96 10.818.154a.691.691 0 0 1 .556-.143c.195.036.37.157.489.336a.872.872 0 0 1 .118.295.928.928 0 0 1-.094.626.776.776 0 0 1-.198.232L6 6 .314 1.5a.775.775 0 0 1-.2-.233.928.928 0 0 1-.095-.63A.871.871 0 0 1 .14.34.782.782 0 0 1 .41.087.674.674 0 0 1 .749 0Z" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill?: string
}
withDefaults(defineProps<Props>(), {
  fill: 'white',
})
</script>

<style scoped></style>
