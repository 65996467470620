<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="347" height="63" fill="none">
    <path
      fill="red"
      d="M30.5 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.44-30 30c0 16.56 13.43 30 30 30Z" />
    <path
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M30.5 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.44-30 30c0 16.56 13.43 30 30 30Z" />
    <path
      fill="#0064FF"
      d="M74.17 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
    <path
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M74.17 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
    <path
      fill="#333"
      d="M152.549 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30" />
    <path
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M152.549 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30v-.01Z" />
    <path
      fill="#696969"
      d="M193.5 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30" />
    <path
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M193.5 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30v-.01Z" />
    <path
      fill="#EEEBEB"
      d="M234.449 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30" />
    <path
      stroke="#A0A0A0"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M234.449 61.8c16.57 0 30-13.43 30-30s-13.43-29.99-30-29.99-30 13.43-30 30 13.43 30 30 30v-.01Z" />
    <path
      fill="#fff"
      d="M275.41 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
    <path
      fill="#FAFAFA"
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M275.41 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
    <path
      fill="#fff"
      d="M316.359 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
    <path
      stroke="#333"
      stroke-miterlimit="10"
      stroke-width=".3"
      d="M316.359 61.8c16.57 0 30-13.43 30-30s-13.43-30-30-30-30 13.43-30 30 13.43 30 30 30Z" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
