<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.02008 14.5189H2.86021C3.06023 14.5189 3.24024 14.4589 3.43025 14.3289H3.60027C3.98029 14.6889 4.45033 14.8789 4.96037 14.8789H10.8208C12.1809 14.8789 13.051 14.1188 13.211 12.8388L14.001 7.74838V7.57837C14.001 6.41828 13.091 5.46821 11.9609 5.46821H9.27068L9.13067 5.3282V3.39806C9.13067 2.40799 8.85065 1.69793 8.30061 1.2879C7.65056 0.797866 6.87051 0.807867 6.19046 1.00788C6.00044 1.07789 5.87043 1.2579 5.87043 1.47792V3.9581C5.87043 4.78816 5.4504 5.43821 4.76035 5.92825C4.39032 6.18826 4.15031 6.29827 3.70027 6.46829L3.56026 6.43828C3.38025 6.24827 3.12023 6.13826 2.85021 6.13826H1.01007C0.460034 6.12826 0 6.59829 0 7.16834V13.4588C0.0200015 14.0488 0.480035 14.5189 1.02008 14.5189ZM3.87029 7.55837L3.97029 7.41835C4.44033 7.26834 5.00037 6.99832 5.52041 6.57829C6.34047 5.91824 6.8105 5.06818 6.8105 3.9781V2.02796L6.94051 1.88795C7.25054 1.85794 7.56056 1.92795 7.78057 2.08796C8.0906 2.30798 8.23061 2.74801 8.23061 3.39806V5.96825C8.23061 6.22827 8.44062 6.44828 8.69064 6.44828H11.9809C12.5909 6.44828 13.091 6.95832 13.091 7.59837V7.63837L13.001 8.22841L12.8609 8.34842H8.84065C8.59063 8.42843 8.43062 8.63844 8.43062 8.83846C8.43062 9.04848 8.59063 9.24849 8.80065 9.3285H12.8309L12.6109 10.7686L12.4709 10.8886H8.83065C8.59063 10.9286 8.43062 11.1186 8.43062 11.3786C8.43062 11.6387 8.58063 11.8087 8.81065 11.8387H12.4409L12.4109 11.9987C12.3909 12.1387 12.3509 12.3787 12.2909 12.6887C12.2909 12.6887 12.2909 12.7087 12.2909 12.7287C12.2609 12.9688 12.2309 13.0888 12.1509 13.2488C11.9509 13.6688 11.5309 13.9188 10.8308 13.9188H4.97037C4.36032 13.9188 3.86028 13.4088 3.86028 12.7688V7.54836L3.87029 7.55837ZM0.95007 7.25834L1.09008 7.11833H2.79021L2.93022 7.25834V13.4188L2.79021 13.5588H1.09008L0.95007 13.4188V7.25834Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-1'
})
</script>
